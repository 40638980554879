
  import { Component, Vue } from "vue-property-decorator";
  import LzButton from "@/components/Button.vue";
  import { WandIcon } from "vue-tabler-icons";
  import { apiLogin } from "../api";
  import { namespace } from "vuex-class";

  const store = namespace("auth");

  @Component({
    components: { LzButton, WandIcon }
  })
  export default class Signin extends Vue {
    signinForm = {
      email: "",
      password: ""
    };
    loadingPostLogin = false;

    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateData!: (payload: any) => void;

    @store.Action
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateFeatures!: (payload: any) => void;

    onSigninSubmit() {
      this.loadingPostLogin = true;

      apiLogin({
        email: this.signinForm.email,
        password: this.signinForm.password
      })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(async (response: any) => {
          const { token, data } = response;
          if (token) {
            localStorage.setItem("accessToken", token);
            localStorage.setItem("auth", JSON.stringify(data));
            await this.updateData(data);
            await this.updateFeatures(data.id);
            this.$router.push({ name: "Home" });
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            return this.$notify({
              type: "error",
              text: this.$tc("auth.signin.errors.wrongCredentials")
            });
          }
          this.$notify({
            type: "error",
            text: this.$tc("common.error.generic")
          });
        })
        .finally(() => {
          this.loadingPostLogin = false;
        });
    }
  }
